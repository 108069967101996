<template>
	<div class="auth-wrapper auth-v2">
		<b-row class="auth-inner m-0">
			<!-- Brand logo-->
			<b-link class="brand-logo">
				<vuexy-logo />
			</b-link>
			<!-- /Brand logo-->

			<!-- Left Text-->
			<b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
				<div
					class="
						w-100
						d-lg-flex
						align-items-center
						justify-content-center
						px-5
					"
				>
					<b-img fluid :src="imgUrl" alt="Login V2" />
				</div>
			</b-col>
			<!-- /Left Text-->

			<!-- Login-->
			<b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
				<b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
					<b-card-title class="mb-1 font-weight-bold" title-tag="h2">
						{{$t('Welcome to ') + appName }}! 👋
					</b-card-title>

					<!-- form -->
					<validation-observer ref="loginForm" #default="{ invalid }">
						<b-form
							class="auth-login-form mt-2"
							@submit.prevent="login"
						>
							<!-- email -->
							<b-form-group
								:label="$t('Username')"
								label-for="login-username"
							>
								<validation-provider
									#default="{ errors }"
									name="Username"
									vid="username"
									rules="required"
								>
									<b-form-input
										id="login-username"
										v-model="username"
										:state="
											errors.length > 0 ? false : null
										"
										name="login-username"
										:placeholder="$t('Your username')"
									/>
									<small class="text-danger">{{
										errors[0]
									}}</small>
								</validation-provider>
							</b-form-group>

							<!-- password -->
							<b-form-group>
								<div class="d-flex justify-content-between">
									<label for="login-password">{{$t('Password')}}</label>
									<b-link
                    v-if="!isResetPasswordRequired"
										:to="{ name: 'auth-forgot-password' }"
									>
										<small>{{$t('Forgot Password')}}?</small>
									</b-link>
								</div>
								<validation-provider
									#default="{ errors }"
									name="Password"
									vid="password"
									rules="required"
								>
									<b-input-group
										class="input-group-merge"
										:class="
											errors.length > 0
												? 'is-invalid'
												: null
										"
									>
										<b-form-input
											id="login-password"
											v-model="password"
											:state="
												errors.length > 0 ? false : null
											"
											class="form-control-merge"
											:type="passwordFieldType"
											name="login-password"
											:placeholder="$t('Your password')"
										/>
										<b-input-group-append is-text>
											<feather-icon
												class="cursor-pointer"
												:icon="passwordToggleIcon"
												@click="
													togglePasswordVisibility
												"
											/>
										</b-input-group-append>
									</b-input-group>
									<small class="text-danger">{{
										errors[0]
									}}</small>
								</validation-provider>
							</b-form-group>

              <!-- new password -->
              <b-form-group v-if="isResetPasswordRequired">
                <div class="d-flex justify-content-between">
                  <label for="login-new-password">{{$t('New Password')}}</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="New Password"
                  vid="newPassword"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="
                    errors.length > 0
                      ? 'is-invalid'
                      : null
                  "
                  >
                    <b-form-input
                      id="login-new-password"
                      v-model="newPassword"
                      :state="
                      errors.length > 0 ? false : null
                    "
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      :placeholder="$t('New password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="
                        togglePasswordVisibility
                      "
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{
                      errors[0]
                    }}</small>
                </validation-provider>
              </b-form-group>

              <!-- new password repeat -->
              <b-form-group v-if="isResetPasswordRequired">
                <div class="d-flex justify-content-between">
                  <label for="login-new-password-repeat">{{$t('Repeat New Password')}}</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Repeat New Password"
                  vid="newPasswordRepeat"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="
                    errors.length > 0
                      ? 'is-invalid'
                      : null
                  "
                  >
                    <b-form-input
                      id="login-new-password"
                      v-model="newPasswordRepeat"
                      :state="
                      errors.length > 0 ? false : null
                    "
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-new-password-repeat"
                      :placeholder="$t('Repeat New password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="
                        togglePasswordVisibility
                      "
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{
                      errors[0]
                    }}</small>
                </validation-provider>
              </b-form-group>

              <!-- mfa -->
              <b-form-group v-if="isMfaRequired">
                <div class="d-flex justify-content-between">
                  <label for="login-mfa-token">{{$t('MFA Code')}}</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="MFA Code"
                  vid="mfaToken"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="
                    errors.length > 0
                      ? 'is-invalid'
                      : null
                  "
                  >
                    <b-form-input
                      id="login-mfa-token"
                      v-model="mfaToken"
                      :state="
                      errors.length > 0 ? false : null
                    "
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-mfa-token"
                      :placeholder="$t('MFA Token')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="
                        togglePasswordVisibility
                      "
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{
                      errors[0]
                    }}</small>
                </validation-provider>
              </b-form-group>

							<!-- checkbox -->
							<!-- <b-form-group>
								<b-form-checkbox
									id="remember-me"
									v-model="status"
									name="checkbox-1"
								>
									Remember Me
								</b-form-checkbox>
							</b-form-group> -->

							<!-- submit buttons -->
							<b-button
								type="submit"
								variant="primary"
								block
								:disabled="invalid"
							>
								{{$t('Sign in')}}
							</b-button>
						</b-form>
					</validation-observer>
				</b-col>
			</b-col>
			<!-- /Login-->
		</b-row>
	</div>
</template>

<script>
	/* eslint-disable global-require */
	import { ValidationProvider, ValidationObserver } from "vee-validate";
	import VuexyLogo from "@core/layouts/components/Logo.vue";
	import {
		BRow,
		BCol,
		BLink,
		BFormGroup,
		BFormInput,
		BInputGroupAppend,
		BInputGroup,
		BFormCheckbox,
		BCardText,
		BCardTitle,
		BImg,
		BForm,
		BButton,
		BAlert,
		VBTooltip,
	} from "bootstrap-vue";
	import { required } from "@validations";
	import { togglePasswordVisibility } from "@core/mixins/ui/forms";
	import store from "@/store/index";
	import { getHomeRouteForLoggedInUser } from "@/auth/utils";
	import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

	import { fetcher, FetchMethod } from "@/libs/axios";
	import apis from "@configs/apis";
	import {
		setAccessToken,
		setProfile,
    setCrmProfile,
    setErpProfile,
		getPermissions,
	} from "@/utils/account-localstorage";
  import Prompt from "@/views/erp/mixins/Prompt";
  import * as Sentry from "@sentry/vue";
  import { $themeConfig } from "@themeConfig";

	export default {
		directives: {
			"b-tooltip": VBTooltip,
		},
		components: {
			BRow,
			BCol,
			BLink,
			BFormGroup,
			BFormInput,
			BInputGroupAppend,
			BInputGroup,
			BFormCheckbox,
			BCardText,
			BCardTitle,
			BImg,
			BForm,
			BButton,
			BAlert,
			VuexyLogo,
			ValidationProvider,
			ValidationObserver,
		},
		mixins: [togglePasswordVisibility, Prompt],
		data() {
			return {
				status: "",
				password: null,
				username: null,
				sideImg: require("@/assets/images/pages/login-v2.svg"),

        isResetPasswordRequired: false,
        newPassword: null,
        newPasswordRepeat: null,

        isMfaRequired: false,
        mfaToken: null,

				// validation rules
				required,
			};
		},
		computed: {
			passwordToggleIcon() {
				return this.passwordFieldType === "password"
					? "EyeIcon"
					: "EyeOffIcon";
			},
			imgUrl() {
				if (store.state.appConfig.layout.skin === "dark") {
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
					return this.sideImg;
				}
				return this.sideImg;
			},
		},
		methods: {
			login() {
				this.$refs.loginForm.validate().then(async (success) => {
          if (this.newPassword !== this.newPasswordRepeat) {
            this.promptError('New password and repeat new password must be the same')
            return
          }

					if (success) {
						try {
							const loginResult = await fetcher(
								apis.login,
								FetchMethod.POST,
								{
                  username: this.username,
                  password: this.password,
                  newPassword: this.newPassword,
                  mfaToken: this.mfaToken,
                }
							);

              if (!loginResult.isSuccess) {
                if (loginResult.isPasswordExpired) {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: "Password expired",
                      icon: "BellIcon",
                      variant: "danger",
                      text: loginResult.message,
                    },
                  });
                  this.isResetPasswordRequired = true;
                } else if (loginResult.isMfaRequired) {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: "MFA required",
                      icon: "BellIcon",
                      variant: "danger",
                      text: loginResult.message,
                    },
                  });
                  this.isMfaRequired = true;
                } else {
                  this.promptError(loginResult.message)
                }
                return
              }

							setAccessToken(loginResult.token, loginResult.tokenExpireAt);

              Sentry.setUser({ username: this.username });

							const userProfileRes = await fetcher(
								apis.profile,
								FetchMethod.GET
							);
							setProfile(userProfileRes);

							this.$ability.update(getPermissions());

              // CRM Permissions
              if (this.$can('manage', 'crm')) {
                try {
                  const response = await fetcher(
                    apis.crmGetMe,
                    FetchMethod.GET
                  );
                  setCrmProfile(response);
                } catch (e) {
                  this.promptError('CRM role not assigned. Please ask your administrator for assistance.')
                  console.info(e.message);
                }
              }

              // ERP Permissions
              if (this.$can('manage', 'erp')) {
                try {
                  const response = await fetcher(
                    apis.erpGetMe,
                    FetchMethod.GET
                  );
                  setErpProfile(response);
                } catch (e) {
                  this.promptError('ERP role not assigned. Please ask your administrator for assistance.')
                  console.info(e.message);
                }
              }

							this.$router.push(getHomeRouteForLoggedInUser());

							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									title: `Welcome ${
										userProfileRes.fullName ||
										userProfileRes.username
									}`,
									icon: "CoffeeIcon",
									variant: "success",
									text: `You have successfully logged in. Now you can start to explore!`,
								},
							});
						} catch (e) {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									title: "Login Fail",
									icon: "BellIcon",
									variant: "danger",
									text: e.detail,
								},
							});
						}
					}
				});
			},
		},
    setup(props) {
      // App Name
      const { appName, appLogoImage } = $themeConfig.app;

      return {
        appName,
        appLogoImage,
      };
    }
	};
</script>

<style lang="scss">
	@import "@core/scss/vue/pages/page-auth.scss";
</style>